const initialState = {
  memberInfo: {},
  userPointsRecord: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_MEMBER_INFO': {
      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          ...action.payload.memberInfo,
        },
      }
    }
    case 'SET_USER_POINTS_RECORD': {
      return {
        ...state,
        userPointsRecord: action.payload,
      }
    }
    default:
      return state
  }
}

export default reducer
