const initialState = {
  categories: [],
  selectedCategoryId: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SELECTED_CATEGORY_ID': {
      return {
        ...state,
        selectedCategoryId: action.payload.categoryId,
      }
    }
    default:
      return state
  }
}

export default reducer
