import { combineReducers } from 'redux'
import category from './categoryReducer'
import member from './memberReducer'
import product from './productReducer'
import order from './orderReducer'

export default combineReducers({
  category,
  member,
  product,
  order,
})
