import { SessionProvider } from 'next-auth/react'
import 'dotenv/config'
// import type { AppProps } from "next/app"
import '../styles/globals.css'
import { Provider } from 'react-redux'
import Head from 'next/head'
import store from '../store'
import Messenger from '../components/Messenger'
import Loader from '../components/Loader'

// Use of the <SessionProvider> is mandatory to allow components that call
// `useSession()` anywhere in your application to access the `session` object.
// export default function App({ Component, pageProps }: AppProps) {
export default function App({ Component, pageProps: { session, ...pageProps } }) {
  const withLayout = Component.withLayout || ((page) => page)

  return (
    <Provider store={store}>
      <SessionProvider session={session} refetchInterval={0}>
        <Loader />
        <Messenger />
        <Head>
          <title>百變怪貼紙 StickerHD</title>
          <meta
            name="description"
            content="百變怪貼紙，專注完美的貼紙印刷 免費線上校稿、少量也能印刷、絕對高品質、防水抗UV 超高速出貨到貨，馬上輕鬆客製化貼紙！"
          />
        </Head>

        {withLayout(<Component {...pageProps} />)}
      </SessionProvider>
    </Provider>
  )
}
